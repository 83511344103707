import { colorPalette } from "gx-npm-common-styles";
import { ExclamationCircledIcon } from "gx-npm-icons";
import { TypographyComponent } from "gx-npm-ui";
import styles from "./error-banner.module.scss";

type ErrorBannerProps = { message?: string };
const ErrorBannerComponent = ({ message = "" }: ErrorBannerProps) => {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.iconWrapper}>
        <ExclamationCircledIcon fillPath={colorPalette.status.poisonCherry.hex} title={""} />
      </div>
      <TypographyComponent boldness="medium" color="poisonCherry" rootClassName={styles.message} styling="p3">
        {message}
      </TypographyComponent>
    </div>
  );
};

export default ErrorBannerComponent;
