import { commonTheme } from "gx-npm-common-styles";
import { useUserState } from "gx-npm-lib";
import { publish } from "gx-npm-messenger-util";
import { TypographyComponent } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import * as reactDeviceDetect from "react-device-detect";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import { AppContext } from "../../../../app.context";
import LoginButton from "../login-button/login-button.component";
import { LoginFormContext } from "../login-form.context";
import styles from "./first-time-login.module.scss";

const FirstTimeLogin = () => {
  const { t } = useTranslation();
  const [subWelcomeStyling, setSubWelcomeStyling] = useState<"h5" | "p2">("h5");
  const [welcomeStyling, setWelcomeStyling] = useState<"h2" | "p2">("h2");
  const { firstName, lastName, isOnboarded } = useUserState();
  const { setShowMobileMessage, redirectUrl, setShowOnboardingExperience } = useContext(AppContext);
  const { setShowSso } = useContext(LoginFormContext);

  useEffect(() => {
    const handleResize = () => {
      const { md } = commonTheme.breakpoints.values;
      const width = window.innerWidth;

      if (width < md) {
        setSubWelcomeStyling("p2");
        setWelcomeStyling("p2");
      } else if (width >= md) {
        setSubWelcomeStyling("h5");
        setWelcomeStyling("h2");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClickButtonSwitchAcct = () => {
    setShowSso(false);
    publish("CLEAR_USER_STATE");
    publish("WS_DISCONNECT");
  };

  const handleSubmit = () => {
    if (reactDeviceDetect.isMobile) {
      setShowMobileMessage(true);
    } else if (!isOnboarded) {
      setShowOnboardingExperience(true);
    } else {
      navigateToUrl(redirectUrl);
    }
  };

  return (
    <div aria-label={t("first time user card")} className={styles.firstTimeContainer}>
      <TypographyComponent styling={welcomeStyling} boldness="medium" element="h2">
        {t("Welcome")}
        {firstName && lastName && (
          <Fragment>
            {`, `}
            <br />
            <TypographyComponent styling={welcomeStyling} boldness="medium" color="yam" element="span">
              {`${firstName} `}
              {`${lastName}!`}
            </TypographyComponent>
          </Fragment>
        )}
      </TypographyComponent>
      <TypographyComponent
        styling={subWelcomeStyling}
        boldness="medium"
        color="coal"
        element="h3"
        rootClassName={styles.secondHeading}
      >
        {t("As a Gartner client, you have instant access to BuySmart. Let's get you set up.")}
      </TypographyComponent>
      <div className={styles.divider} />
      <div>
        <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.notName} element="p">
          {`${t("Not")} ${firstName} ${lastName}? `}
        </TypographyComponent>
        <button className={styles.switchAccounts} onClick={onClickButtonSwitchAcct}>
          <TypographyComponent boldness="medium" color={"defaultCta"} element="span" styling="p3">
            {t("Switch accounts")}
          </TypographyComponent>
        </button>
      </div>
      <LoginButton onSubmit={handleSubmit} />
    </div>
  );
};

export default FirstTimeLogin;
