const environmentFromWindowLocation = () => {
  let env = "prod";
  const domain = window?.location?.host || "";
  if (domain) {
    if (domain.indexOf("pdodev") > -1) {
      env = "dev";
    } else if (domain.indexOf("pdoqa") > -1) {
      env = "qa";
    } else if (domain.indexOf("localhost") > -1) {
      env = "local";
    }
  }
  return env;
};

const getGcomDomain = () => {
  const env = environmentFromWindowLocation();
  if (env === "prod") {
    return "https://www.gartner.com";
  }
  if (env === "qa") {
    return "https://gcom.pdoqa.aws.gartner.com";
  }
  return "https://gcom.pdodev.aws.gartner.com";
};

export { environmentFromWindowLocation, getGcomDomain };
