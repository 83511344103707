import styles from "./right-side-clips.styles.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import { useContext, useState, useEffect } from "react";
import OnBoardingStepTwo from "../../../assets/lottie/onboarding-step-two.json";
import OnBoardingStepThree from "../../../assets/lottie/onboarding-step-three.json";
import OnBoardingStepOne from "./onboarding-step-one-video/onboarding-step-one-video.component";
import { AppContext } from "../../../app.context";
import { OnboardingSteps } from "../../../app.constants";
import classNames from "classnames";

const RightSideClips = () => {
  const { step } = useContext(AppContext);
  const [displayedStep, setDisplayedStep] = useState(step);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDisplayedStep(step);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [step]);

  return (
    <div className={styles.rightSide}>
      <div className={styles.bg}>
        <div className={styles.apricotBg} />
        <div className={styles.blueBg} />
      </div>
      <div className={classNames(styles.stepOne, { [styles.visible]: displayedStep === OnboardingSteps.STEP_ONE })}>
        {displayedStep === OnboardingSteps.STEP_ONE && <OnBoardingStepOne />}
      </div>
      <div className={classNames(styles.player, { [styles.visible]: displayedStep === OnboardingSteps.STEP_TWO })}>
        {displayedStep === OnboardingSteps.STEP_TWO && (
          <Player keepLastFrame={true} autoplay={true} loop={false} src={OnBoardingStepTwo} />
        )}
      </div>
      <div className={classNames(styles.player, { [styles.visible]: displayedStep === OnboardingSteps.STEP_THREE })}>
        {displayedStep === OnboardingSteps.STEP_THREE && (
          <Player keepLastFrame={true} autoplay={true} loop={false} src={OnBoardingStepThree} />
        )}
      </div>
    </div>
  );
};

export default RightSideClips;
