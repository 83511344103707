import { Drawer } from "@material-ui/core";
import { postAsyncRequest, useCaptureEventsV2, UUID } from "gx-npm-lib";
import { useContext, useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import { ClientEvent, ClientEventOnboardingStep, OnboardingUser } from "../../app.constants";
import { AppContext } from "../../app.context";
import LeftSideText from "./left-side-text/left-side-text.component";
import RightSideClips from "./right-side-clips/right-side-clips.component";
import styles from "./onboarding-overlay.styles.module.scss";

type OnboardingOverlayProps = { isOpen: boolean };
const OnboardingOverlay = ({ isOpen = false }: OnboardingOverlayProps) => {
  const captureEvents = useCaptureEventsV2();
  const { onboardingUser, setEventId, eventId } = useContext(AppContext);

  useEffect(() => {
    if (eventId) {
      return;
    }
    const id = uuidV4() as UUID;
    setEventId(id);
  }, [eventId, setEventId]);

  useEffect(() => {
    if (!isOpen || !eventId || onboardingUser === OnboardingUser.NONE) {
      return;
    }
    captureEvents([
      {
        eventType: ClientEvent.LOGIN_USER_ONBOARDING,
        metaData: { step: ClientEventOnboardingStep.START, userType: onboardingUser, eventId },
      },
    ]);
  }, [captureEvents, isOpen, onboardingUser, eventId]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    postAsyncRequest("api/v2/user/onboard");
  }, [isOpen]);
  return (
    <div>
      <Drawer
        transitionDuration={0}
        classes={{ paper: styles.drawer }}
        open={isOpen}
        className={styles.onboardingOverlay}
      >
        <div className={styles.bg}>
          <div className={styles.onboardingContent} role="complementary">
            <LeftSideText />
            <RightSideClips />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default OnboardingOverlay;
