import { ButtonLoader } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LoginFormContext } from "../login-form.context";
import styles from "./login-button.module.scss";

const LoginButton = ({ onSubmit, disabled = false }: { onSubmit: () => void; disabled?: boolean }) => {
  const { t } = useTranslation();
  const { isLoading } = useContext(LoginFormContext);

  return (
    <ButtonLoader
      btnClass="btn-primary"
      btnRootClassName="gx-dragon-login-btn"
      isLoading={isLoading}
      onClick={onSubmit}
      rootClassName={styles.loginButton}
      disabled={disabled}
    >
      {t("Get started")}
    </ButtonLoader>
  );
};
export default LoginButton;
