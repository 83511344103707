import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useGxtmParams = () => {
  const { search } = useLocation();
  const [gxtmParams, setGxtmParams] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const redirect = searchParams.get("redirect");
    let gxtmPage = searchParams.get("gxtm_page");
    let gxtmSource = searchParams.get("gxtm_source");

    if (!gxtmSource && !redirect) {
      return;
    }
    if (!gxtmSource && redirect) {
      const decodedRedirectSearch = decodeURI(redirect).split("?")[1];
      const redirectSearchParams = new URLSearchParams(decodedRedirectSearch);
      gxtmPage = redirectSearchParams.get("gxtm_page");
      gxtmSource = redirectSearchParams.get("gxtm_source");
    }
    if (!gxtmSource) {
      return;
    }
    setGxtmParams(`gxtm_source=${gxtmSource}${gxtmPage ? `&gxtm_page=${gxtmPage}` : ""}`);
  }, [search]);

  return { gxtmParams };
};
