import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { commonTheme } from "gx-npm-common-styles";
import { FeatureFlagProvider } from "gx-npm-ui";
import { messengerFlagLoader } from "gx-npm-lib";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUpV2Component from "./sections/sign-up-v2/sign-up-v2.component";
import LoginV2Component from "./sections/login-v2/login-v2.component";
import { AppContextProvider } from "./app.context";

const generateClassName = createGenerateClassName({ seed: "login-app" });
const theme = createMuiTheme(commonTheme);
export default function Root() {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          <AppContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/signup" element={<SignUpV2Component />} />
                <Route path="*" element={<LoginV2Component />} />
              </Routes>
            </BrowserRouter>
          </AppContextProvider>
        </StylesProvider>
      </ThemeProvider>
    </FeatureFlagProvider>
  );
}
