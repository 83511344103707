import { createContext, ReactNode, useState } from "react";
import { LoginFormContextValue } from "./login-form.types";

const LoginFormContext = createContext<LoginFormContextValue>({} as LoginFormContextValue);
const LoginFormContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [isSuccessfulSso, setIsSuccessfulSso] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(false);
  const [urlPageRecovery, setUrlPageRecovery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [urlPageRedirect, setUrlPageRedirect] = useState("");
  const [showSso, setShowSso] = useState(false);

  const contextValue = {
    showPassword,
    setShowPassword,
    password,
    setPassword,
    email,
    setEmail,
    remember,
    setRemember,
    urlPageRecovery,
    setUrlPageRecovery,
    isLoading,
    setIsLoading,
    errorText,
    setErrorText,
    urlPageRedirect,
    setUrlPageRedirect,
    showSso,
    setShowSso,
    isSuccessfulSso,
    setIsSuccessfulSso,
  };

  return <LoginFormContext.Provider value={contextValue}>{props.children}</LoginFormContext.Provider>;
};

export { LoginFormContext, LoginFormContextProvider };
