import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./sign-up-form-options.styles.module.scss";
import { SignUpFormContext } from "../sign-up-form.context";

const SignUpFormOptions = () => {
  const { t } = useTranslation();
  const { termsPolicyAgreement, setTermsPolicyAgreement } = useContext(SignUpFormContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsPolicyAgreement(event.target.checked);
  };

  return (
    <Checkbox
      checked={termsPolicyAgreement}
      inputProps={{ "aria-label": t("Agree Terms") }}
      label={
        <div>
          <TypographyComponent styling={"p3"} element={"span"} boldness={"regular"}>
            {`${t("I agree to Gartner")} `}
          </TypographyComponent>{" "}
          <TextLink href="https://www.gartner.com/en/about/policies/terms-of-use" target="_blank" text={t("Terms")} />
          {` ${t("and")} `}
          <TextLink
            href="https://www.gartner.com/en/about/policies/privacy"
            target="_blank"
            text={t("Privacy Policy.")}
          />
        </div>
      }
      name="agree"
      onChange={handleChange}
      rootClassName={styles.agreeTerms}
    />
  );
};

export default SignUpFormOptions;
