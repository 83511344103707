export enum OnboardingSteps {
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
}
export enum OnboardingUser {
  NONE = "NONE",
  EVALUATOR = "EVALUATOR",
  SURVEY_RECIPIENT = "SURVEY_RECIPIENT",
}
export enum ClientEvent {
  LOGIN_USER_ONBOARDING = "LOGIN_USER_ONBOARDING",
}
export enum ClientEventOnboardingStep {
  START = "START",
  STEP_TWO = "STEP_TWO",
  STEP_THREE = "STEP_THREE",
  FINISH = "FINISH",
}
