/**
 * cleans a URL for javascript reflective XSS vulnerabilities
 * @param {string} rawRedirect redirect param from original query string
 * @returns string of cleaned redirect URL
 */
const constructValidRedirect = (rawRedirect) => {
  if (!rawRedirect) {
    return "";
  }

  try {
    let temp = decodeURIComponent(rawRedirect);
    if (!isURL(temp)) {
      new URL(window.location.origin + temp);
      return temp;
    }

    const protocol = new URL(temp).protocol;
    if (protocol === "http:" || protocol === "https:") {
      return temp;
    }
  } catch (_ignored) {
    // do nothing
  }
  return "";
};

const isURL = (url) => {
  let isValid = false;
  try {
    new URL(url);
    isValid = true;
  } catch (_ignored) {
    // do nothing
  }
  return isValid;
};

const environmentFromWindowLocation = () => {
  let env = "prod";
  const domain = window?.location?.host || "";
  if (domain) {
    if (domain.indexOf("pdodev") > -1) {
      env = "dev";
    } else if (domain.indexOf("pdoqa") > -1) {
      env = "qa";
    } else if (domain.indexOf("localhost") > -1) {
      env = "local";
    }
  }
  return env;
};

export { constructValidRedirect, environmentFromWindowLocation };
