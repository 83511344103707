import { commonTheme } from "gx-npm-common-styles";
import { TypographyBoldness, TypographyComponent, TypographyStyling } from "gx-npm-ui";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GartnerBuySmartLogoSVG from "../../../assets/images/gartner-buy-smart-logo.svg";
import useTokenParam from "../../../hooks/token-param.hook";
import styles from "./welcome-message.styles.module.scss";

const WelcomeMessageComponent = () => {
  const { t } = useTranslation();
  const [messageBoldness, setMessageBoldness] = useState<TypographyBoldness>("semi");
  const [messageStyling, setMessageStyling] = useState<TypographyStyling>("h4");
  const [initTitleStyling, setInitTitleStyling] = useState<TypographyStyling>("p2");
  const { initTitle, invitedBy, surveyId } = useTokenParam();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= commonTheme.breakpoints.values.xl) {
        setMessageBoldness("medium");
        setMessageStyling("h1");
        setInitTitleStyling("p1");
      } else if (width >= commonTheme.breakpoints.values.md) {
        setMessageBoldness("medium");
        setMessageStyling("h2");
        setInitTitleStyling("p2");
      } else {
        setMessageBoldness("semi");
        setMessageStyling("h4");
        setInitTitleStyling("p2");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.messageContainer}>
      <div className={styles.imgContainer}>
        <img alt={t("Gartner BuySmart logo")} src={GartnerBuySmartLogoSVG} />
      </div>
      {!surveyId ? (
        <Fragment>
          <TypographyComponent boldness={messageBoldness} color="white" element="h1" styling={messageStyling}>
            <TypographyComponent boldness={messageBoldness} color="tangerine" element="span">
              {invitedBy}
            </TypographyComponent>
            {` `}
            {t("has invited you to an evaluation on Gartner BuySmart!")}
            <br />
            <div className={styles.divider} />
            <TypographyComponent color="lightBerry" element="span" styling="p4">
              {t("Evaluation")}{" "}
            </TypographyComponent>
          </TypographyComponent>
          <TypographyComponent
            boldness={initTitleStyling === "p1" ? "medium" : "semi"}
            color="white"
            element="span"
            styling={initTitleStyling}
          >
            {initTitle}
          </TypographyComponent>
        </Fragment>
      ) : (
        <Fragment>
          <TypographyComponent boldness="medium" color="white" element="h1" styling={messageStyling}>
            {t("Join hundreds of other vendors")}
            {` `}
            <br />
            <TypographyComponent color="tangerine" boldness="medium" element="span">
              {t("engaging with prospects")}
            </TypographyComponent>
            {` `}
            {t("on BuySmart.")}
          </TypographyComponent>
        </Fragment>
      )}
    </div>
  );
};

export default WelcomeMessageComponent;
