import { createContext, ReactNode, useState } from "react";
import { AppContextValue } from "./app.types";
import { OnboardingSteps, OnboardingUser } from "./app.constants";
import { UUID } from "gx-npm-lib";
const AppContext = createContext<AppContextValue>({} as AppContextValue);
const AppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [step, setStep] = useState<OnboardingSteps>(OnboardingSteps.STEP_ONE);
  const [onboardingUser, setOnboardingUser] = useState<OnboardingUser>(OnboardingUser.NONE);
  const [showOnboardingExperience, setShowOnboardingExperience] = useState<boolean>(false);
  const [showMobileMessage, setShowMobileMessage] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [signUpUrl, setSignUpUrl] = useState<string>("");
  const [eventId, setEventId] = useState<UUID>("");
  const contextValue = {
    step,
    onboardingUser,
    showOnboardingExperience,
    showMobileMessage,
    redirectUrl,
    signUpUrl,
    eventId,
    setShowMobileMessage,
    setRedirectUrl,
    setSignUpUrl,
    setStep,
    setOnboardingUser,
    setShowOnboardingExperience,
    setEventId,
  };

  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
