import classNames from "classnames";
import { Checkbox, TypographyComponent } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LoginFormContext } from "../login-form.context";
import styles from "./login-form-options.module.scss";
import { getGcomDomain } from "../../login-v2.lib";

const LoginFormOptions = () => {
  const { remember, setRemember } = useContext(LoginFormContext);
  const { t } = useTranslation();

  const urlLinkRecover = "account/recover";
  const gcomDomain = getGcomDomain();
  const urlPageRecovery = gcomDomain ? `${gcomDomain}/${urlLinkRecover}` : "#";
  return (
    <div className={classNames(styles.loginOptions)}>
      <Checkbox
        checked={remember}
        inputProps={{ "aria-label": t("Remember me") }}
        label={t("Remember me")}
        name="remember"
        onChange={(event) => setRemember(!!event.target.checked)}
        rootClassName={styles.rememberMe}
      />
      <div className={classNames(styles.forgotPassword)}>
        <a className={styles.atag} href={urlPageRecovery}>
          <TypographyComponent boldness="medium" color="defaultCta" element="span" styling="p3">
            {`${t("Forgot Password")}?`}
          </TypographyComponent>
        </a>
      </div>
    </div>
  );
};

export default LoginFormOptions;
