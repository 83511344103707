import { getAsyncRequest } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useTokenParam from "../../../hooks/token-param.hook";
import SignUpButton from "./sign-up-form-button/sign-up-form-button.component";
import SignUpFormFields from "./sign-up-form-fields/sign-up-form-fields.component";
import SignUpFormHeader from "./sign-up-form-header/sign-up-form-header.component";
import SignUpFormOptions from "./sign-up-form-options/sign-up-form-options.component";
import SignUpProfileFields from "./sign-up-profile-fields/sign-up-profile-fields.component";
import { SignUpFormContext } from "./sign-up-form.context";
import styles from "./sign-up-form-v2.styles.module.scss";

const SignUpFormV2Component = () => {
  const { t } = useTranslation();
  const { hasEvaluatedToken, isValidToken, surveyId } = useTokenParam();
  const { page, setErrorText, setGcomData } = useContext(SignUpFormContext);

  useEffect(() => {
    if (!hasEvaluatedToken || !isValidToken || surveyId) {
      return;
    }
    (async () => {
      try {
        const response = await getAsyncRequest("/api/v3/data/profile");
        if (response?.status !== 200 || !response.data?.data) {
          throw new Error("Failed to fetch profile data");
        }
        setGcomData({
          companySize: response.data.data.companySize || [],
          functions: response.data.data.functions || [],
          industries: response.data.data.industries || [],
        });
      } catch (error) {
        setErrorText("Failed to fetch profile data. Please try again.");
      }
    })();
  }, [hasEvaluatedToken, isValidToken, surveyId, setErrorText, setGcomData]);

  return (
    <div className={styles.signUpForm}>
      <SignUpFormHeader />
      <form noValidate autoComplete="off">
        {page === 1 ? <SignUpFormFields /> : <SignUpProfileFields />}
        <TypographyComponent boldness="regular" color="poisonCherry" rootClassName={styles.required} styling="p4">
          {t("*Required")}
        </TypographyComponent>
        {page === 1 && <SignUpFormOptions />}
        <SignUpButton />
      </form>
    </div>
  );
};

export default SignUpFormV2Component;
