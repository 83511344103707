import VidyardEmbed from "@vidyard/embed-code";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../../app.context";
import { OnboardingUser } from "../../../../app.constants";
import styles from "./onboarding-step-one-video.module.scss";

const OnBoardingStepOne = () => {
  const evaluatorVideoRef = useRef<HTMLDivElement | null>(null);
  const surveyRecipientVideoRef = useRef<HTMLDivElement | null>(null);
  const { onboardingUser } = useContext(AppContext);

  useEffect(() => {
    if (!evaluatorVideoRef.current) {
      return;
    }
    VidyardEmbed.api.renderPlayer({ container: evaluatorVideoRef.current, uuid: "uUiJWXAKZ9azXXJzNe1HEF" });
  }, [evaluatorVideoRef, onboardingUser]);

  useEffect(() => {
    if (!surveyRecipientVideoRef.current) {
      return;
    }
    VidyardEmbed.api.renderPlayer({ container: surveyRecipientVideoRef.current, uuid: "7ipTRdHCn3RXhoqUka2zSA" });
  }, [surveyRecipientVideoRef, onboardingUser]);

  return (
    <Fragment>
      {onboardingUser === OnboardingUser.SURVEY_RECIPIENT && (
        <div className={styles.videoContainer} ref={surveyRecipientVideoRef} />
      )}
      {onboardingUser === OnboardingUser.EVALUATOR && <div className={styles.videoContainer} ref={evaluatorVideoRef} />}
    </Fragment>
  );
};

export default OnBoardingStepOne;
