import { postAsyncRequest } from "gx-npm-lib";
import { useEffect, useState } from "react";
import BadTokenComponent from "./error-pages/bad-token.component";
import ExpiredTokenComponent from "./error-pages/expired-token.component";
import useTokenParam from "../../hooks/token-param.hook";
import SignUpFormV2Component from "./sign-up-form/sign-up-form-v2.component";
import { SignUpFormContextProvider } from "./sign-up-form/sign-up-form.context";
import WelcomeMessageComponent from "./welcome-message/welcome-message.component";
import styles from "./sign-up-v2.styles.module.scss";

enum TokenError {
  QUERY_PARAM_ISSUE = "QUERY_PARAM_ISSUE",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  UNKNOWN = "UNKNOWN",
}

const SignUpV2Component = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<TokenError | "">("");
  const { token, isValidToken } = useTokenParam();

  useEffect(() => {
    if (!token || !isValidToken) {
      setTokenError(TokenError.QUERY_PARAM_ISSUE);
      return;
    }
    (async () => {
      setIsLoading(true);
      try {
        const response = await postAsyncRequest("api/v3/auth/validate-signup-token", { token });
        if (response?.status !== 200) {
          setTokenError(response?.data?.message || TokenError.UNKNOWN);
        } else {
          setTokenError("");
        }
      } catch (error) {
        setTokenError(TokenError.UNKNOWN);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isValidToken, token]);

  return (
    <div className={styles.signUpContainer}>
      {!isLoading && (
        <div className={styles.contentWrapper}>
          <div className={styles.welcomeBackgroundWrapper}>
            <section className={styles.welcomeMessageContainer}>
              {!tokenError && <WelcomeMessageComponent />}
              {tokenError && tokenError === TokenError.TOKEN_EXPIRED && <ExpiredTokenComponent />}
              {tokenError && tokenError !== TokenError.TOKEN_EXPIRED && <BadTokenComponent />}
            </section>
          </div>
          <SignUpFormContextProvider>
            <section className={styles.signUpFormContainer}>{!tokenError && <SignUpFormV2Component />}</section>
          </SignUpFormContextProvider>
        </div>
      )}
      <div className={styles.triangle} />
    </div>
  );
};

export default SignUpV2Component;
