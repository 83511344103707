import { createContext, ReactNode, useState } from "react";
import { UUID } from "gx-npm-lib";
import { GcomData, RegistrationProfileForm, SignUpFormContextValue } from "./sign-up-form.types";

const SignUpFormContext = createContext<SignUpFormContextValue>({} as SignUpFormContextValue);

const SignUpFormContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [initId, setInitId] = useState<string>("");
  const [initTitle, setInitTitle] = useState<string>("");
  const [invitedBy, setInvitedBy] = useState<string>("");
  const [isBreachedPassword, setIsBreachedPassword] = useState<boolean>(false);
  const [isCompleteProfileForm, setIsCompleteProfileForm] = useState<boolean>(false);
  const [isCompleteSignUpForm, setIsCompleteSignUpForm] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isValidPwd, setIsValidPwd] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [surveyId, setSurveyId] = useState<UUID>("");
  const [termsPolicyAgreement, setTermsPolicyAgreement] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [gcomData, setGcomData] = useState<GcomData>({ companySize: [], industries: [], functions: [] });
  const [profile, setProfile] = useState<RegistrationProfileForm>({
    companySize: "",
    companySizeId: null,
    industry: "",
    industryId: null,
    function: "",
    functionId: null,
    role: "",
    roleId: null,
    title: "",
  });

  const contextValue = {
    confirmPassword,
    setConfirmPassword,
    email,
    setEmail,
    errorText,
    setErrorText,
    firstName,
    setFirstName,
    gcomData,
    setGcomData,
    initId,
    setInitId,
    initTitle,
    setInitTitle,
    invitedBy,
    setInvitedBy,
    isBreachedPassword,
    setIsBreachedPassword,
    isCompleteProfileForm,
    setIsCompleteProfileForm,
    isCompleteSignUpForm,
    setIsCompleteSignUpForm,
    isProcessing,
    setIsProcessing,
    isValidPwd,
    setIsValidPwd,
    lastName,
    setLastName,
    page,
    setPage,
    password,
    setPassword,
    profile,
    setProfile,
    showPassword,
    setShowPassword,
    surveyId,
    setSurveyId,
    termsPolicyAgreement,
    setTermsPolicyAgreement,
    token,
    setToken,
  };

  return <SignUpFormContext.Provider value={contextValue}>{props.children}</SignUpFormContext.Provider>;
};

export { SignUpFormContext, SignUpFormContextProvider };
